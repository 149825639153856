export const Warning = ({ isMobile }: { isMobile: boolean }) => {
  return isMobile ? (
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 2599">
        <path
          id="Rectangle 7876"
          d="M12.251 3.48095C13.013 2.10933 14.9857 2.10933 15.7477 3.48095L25.682 21.3627C26.4226 22.6958 25.4586 24.334 23.9337 24.334H4.06504C2.54007 24.334 1.57614 22.6958 2.31673 21.3627L12.251 3.48095Z"
          stroke="#5F666B"
          stroke-width="2.66667"
        />
        <rect
          id="Rectangle 6377"
          x="13.3327"
          y="19.8962"
          width="1.33333"
          height="1.33333"
          rx="0.666667"
          fill="#5F666B"
          stroke="#5F666B"
          stroke-width="1.33333"
        />
        <path
          id="Vector 165"
          d="M13.999 9.00098L13.999 16.5476"
          stroke="#5F666B"
          stroke-width="2.33333"
          stroke-linecap="round"
        />
      </g>
    </svg>
  ) : (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 2599">
        <path
          id="Rectangle 7876"
          d="M14.8153 4.09972C15.7678 2.3852 18.2335 2.3852 19.186 4.09972L31.6039 26.4519C32.5297 28.1182 31.3247 30.166 29.4185 30.166H4.58277C2.67656 30.166 1.47164 28.1182 2.39738 26.4519L14.8153 4.09972Z"
          stroke="#5F666B"
          stroke-width="3.33333"
        />
        <rect
          id="Rectangle 6377"
          x="16.1673"
          y="24.6195"
          width="1.66667"
          height="1.66667"
          rx="0.833333"
          fill="#5F666B"
          stroke="#5F666B"
          stroke-width="1.66667"
        />
        <path
          id="Vector 165"
          d="M17.001 10.999L17.001 20.4324"
          stroke="#5F666B"
          stroke-width="2.91667"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};
