type ErrorMapType = {
  [key: string]: {
    title: string;
    description?: string;
  };
};

export const customErrorMap: ErrorMapType = {
  PRODUCT_NOT_FOR_SALE: {
    title: '판매가 중단된 상품입니다.',
    description: `더 이상 판매하지 않는 상품으로\n결제를 진행할 수 없습니다.`,
  },
  DEFAULT_ERROR: {
    title: '페이지 접속에 실패했습니다.',
    description: `네트워크가 원활하지 않거나 웹 사이트상 문제로\n접속에 실패했습니다. 잠시 후 다시 시도해 주세요.`,
  },
};
