import { Flex } from '@/_global/_styles/design-system/components';
import {
  Button,
  ButtonColorScheme,
} from '@/_global/_styles/design-system/components/Button/Button';
import { Text } from '@/_global/_styles/design-system/components/Text/Text';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { Warning } from './Warning.svg';
import * as S from './error.css';

type ErrorPageProps = {
  title: string;
  reason?: string;
  additionalInfo?: string;
  button?: {
    text: string;
    onClick: () => void;
    colorScheme?: ButtonColorScheme;
  };
};
/**
 * deviceResponsiveStyle가 적용된 컴포넌트에서 사용할 수 있도록 만든 컴포넌트입니다.
 */
export const ErrorPage = ({ title, reason, additionalInfo, button }: ErrorPageProps) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap={{
        mobile: 40,
        tablet: 50,
      }}
    >
      <Flex
        direction="column"
        align="center"
        gap={{
          mobile: 20,
          tablet: 24,
        }}
      >
        <div className={S.MobileOnly}>
          <Warning isMobile={true} />
        </div>
        <div className={S.DesktopAndTabletOnly}>
          <Warning isMobile={false} />
        </div>
        <Flex
          direction="column"
          align="center"
          justify="center"
          gap={{
            mobile: 8,
            tablet: 10,
          }}
        >
          <Text font={{ mobile: 'mTitle1', tablet: 'wTitle1' }} color={neutralDay.gray100}>
            {title}
          </Text>
          {reason && (
            <Text
              font={{ mobile: 'mBody2', tablet: 'wBody2' }}
              color={neutralDay.gray70}
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
            >
              {reason}
            </Text>
          )}
        </Flex>
      </Flex>

      {additionalInfo && (
        <Flex
          align="center"
          style={{ backgroundColor: neutralDay.gray5, borderRadius: 8, padding: '16px' }}
        >
          <Text font="mBody2" color={neutralDay.gray70}>
            {additionalInfo}
          </Text>
        </Flex>
      )}

      {button && (
        <Button size="md" colorScheme={button.colorScheme} onClick={button.onClick}>
          <span className={S.ButtonTextWrapper}>{button.text}</span>
        </Button>
      )}
    </Flex>
  );
};
