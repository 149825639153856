'use client';

import { ErrorPage } from './_global/_components/Error/ErrorPage';
import { Gnb } from './(_pages)/(withGnb)/_components/Gnb/Gnb';
import { IN_HOUSE_URL } from './_global/_constants/urls';
import { AxiosError } from 'axios';
import { customErrorMap } from './_global/_data/_models/customErrorMap.model';
import * as S from './(_pages)/(withGnb)/_components/style.css';

export default function NotFoundError({ error }: { error: AxiosError }) {
  const message = (error.response?.data as { message: string })?.message;
  const errorInfo = customErrorMap[message] ?? customErrorMap.DEFAULT_ERROR;

  return (
    <div style={{ width: '100%' }}>
      <Gnb />
      <div className={S.NotFoundWrapper}>
        <ErrorPage
          title={errorInfo.title}
          reason={errorInfo.description}
          button={{
            text: '홈으로 가기',
            colorScheme: 'secondary',
            onClick: () => {
              window.location.href = IN_HOUSE_URL.메인;
            },
          }}
        />
      </div>
    </div>
  );
}
